//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//





export default {

     props: {
        rowguid: {
            type: String,
            default: ''
        },
        requesturl:{
            type: String,
            default:'/bhtong/settmg/settItemChild/invoice'
        },
        needinvoice:{
            type: String,
            default: '',
        }
    },
    components:{},
    data: function(){
        return{
            form:{
                file:'',
                number:''
            },
            fpDetail:{},
        }
    }, 
    computed:{
        
    },
    watch:{
    },

    methods: {
        submit:function(){  
            if(this.needinvoice == 1){
                if(this.form.file){
                    this.$post(this.requesturl,{
                        rowguid:this.rowguid,
                        invoiceFile:this.form.file,
                        invoiceNo:this.form.number,
                    },function(data,res){
                        if(res.code == 'v'){
                            this.cancle();
                            ShowMsg('提交成功','success');
                        }else{
                            ShowMsg(res.msg,'warning');
                        }
                    })
                }else{
                    ShowMsg('请上传发票文件','warning');
                }
            }else{
                this.$post(this.requesturl,{
                    rowguid:this.rowguid,
                    invoiceFile:this.form.file,
                    invoiceNo:this.form.number,
                },function(data,res){
                    if(res.code == 'v'){
                        this.cancle();
                        ShowMsg('提交成功','success');
                    }else{
                        ShowMsg(res.msg,'warning');
                    }
                })
            }
        },
        cancle:function(){
            this.$emit('close');
        },
        getfile:function(val){
            this.form.file = val.fileUrl;
        },
        getDetail:function(){
            this.$get(`${this.getGetters('settUrl')}/settItemChild/invoiceInfo`,{
                rowguid:this.rowguid,
            },function(data){
                this.fpDetail = data;
            })
        },
        //获取惠采购详情
        getHcgDetail:function(){
            this.$get(`${this.getGetters('settUrl')}/hcgSettItem/invoiceInfo`,{
                rowguid:this.rowguid,
            },function(data){
                this.fpDetail = data;
            })
        },

    },
    mounted() {
        if(this.requesturl == '/bhtong/settmg/hcgSettItem/invoice'){
            this.getHcgDetail();
        }else{
            this.getDetail();
        }
    }
}
