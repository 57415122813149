//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import settlementDatail from './settlementDatail.vue'
export default {

    props:[],
    components:{
        settlementDatail,
    },
    data: function(){
        return{
            form:{
                itemAmount:'',
                settAmount:'',
                jbzhzh:'' ,
                orderList:[],

            },
            pgData:{
               settStart:'',
               settEnd:''
            },
            selectvalue:'',
            loading:false,
            projectList:[],
            projectStr:'',
            selectvalueList:[
                {value:'按日期结算',key:'1'},
                {value:'按项目结算',key:'2'},
                {value:'按招标代理结算',key:'3'},
            ],
            zbdlList:[],
            zbdlStr:'',
            settlementDatailDialog:false,
        }
    }, 
    computed:{
        valuetime:{
            get:function(){
                var startTime = this.pgData.settStart || '';
                var endtime = this.pgData.settEnd || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.settStart = '' ;
                    this.pgData.settEnd = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.settStart = startTime;
                    this.pgData.settEnd = endtime;
                }
            },
        }  
    },
    watch:{
        selectvalue:function(n,o){
            if(n != o ){
                this.pgData.settStart = '';
                this.pgData.settEnd = '';
                this.form.settBidsection = '';
                this.projectStr ='';
                this.projectList = [];
                this.zbdlStr ='';
                this.zbdlList = [];
                this.getInfo('','','','');
            }
        }
    },

    methods: {
        cancle:function(){
            this.$emit('close');
        },
        getInfo:function(val1,val2,val3,val4){
            var that = this;
            that.$get(`${that.getGetters('settUrl')}/hcgSettItem/applyInfo`,{
                settStart:val1,
                settEnd:val2,
                bidsection:val3,
                bidAgencyName:val4
            },function(data){
                that.form.itemAmount= data.itemAmount;
                that.form.settAmount = data.settAmount;
                that.form.jbzhzh = data.jbzhzh;
                that.form.orderList = data.orderList;
            })
        },
        submitTip:function(){
                var that = this;
                showConfirm('请核实项目名称，如含有已添加结算的项目系统将自动取消，确定将提交合作商确认结算。', 'warning', () => {
                that.new_submit();
            });
        },

        new_submit:function(){
            var that =this;
                let obj = {
                    settType:that.selectvalue,
                    itemName:that.form.itemName,
                    settStart:that.pgData.settStart,
                    settEnd:that.pgData.settEnd,
                    appPhone:that.form.appPhone,
                    remark:that.form.remark,
                    settBidsection: that.projectStr,
                    settBidAgencyName: that.zbdlStr,
                }
                // let newObj = JSON.stringify(obj); 
                // let token = that.getQuery('token') ? that.getQuery('token') : ''
                // $.ajax({
                //         url:"/bhtong/settmg/hcgSettItem/applySett",
                //         type:"POST",
                //         data:newObj,
                //         headers:{'Content-Type': 'application/json','token':token},
                //         success:function(data){
                //             if(data.code == 'v'){
                //                 that.cancle();
                //                 ShowMsg('申请成功','success');
                //             }else{
                //                 ShowMsg(data.msg,'warning');
                //             }
                //         },
                //         error:function(){

                //         }
                // })
                that.$post('/bhtong/settmg/hcgSettItem/applySett', obj ,function(data){
                    that.cancle();
                    ShowMsg('申请成功','success');
                },true)


        },
        submit:function(){
            var that =this;
            that.new_submit();
        },
        changeDay:function(val){
            if(val){
                this.getInfo(val[0],val[1],'','');
            }else{
                 this.getInfo('','','','');
            }
        },
        //项目名称远程搜索
        projectListFilter:function(query){
            if (query !== '') {
                this.loading = true;
                this.$get('/bhtong/ordermg/hcgOrder/listSelect',{bidsection:query},function(data){
                    this.projectList = data;
                    this.loading = false;
                });
            } else {
                this.projectList = [];
            }
        }, 
        projectSelect:function(val){
            if(val == ''){
                this.projectList = [];
            }
            this.projectStr = val.toString();
            this.getInfo('','',this.projectStr,'');
        },
        //招标代理远程搜索
        zbdlListFilter:function(query){
            if (query !== '') {
                this.loading = true;
                this.$get('/bhtong/ordermg/hcgOrder/listSelectBidAgency',{bidAgencyName:query},function(data){
                    this.zbdlList = data;
                    this.loading = false;
                });
            } else {
                this.zbdlList = [];
            }
        }, 
        zbdlSelect:function(val){
            if(val == ''){
                this.zbdlList = [];
            }
            this.zbdlStr = val.toString();
            this.getInfo('','','',this.zbdlStr);
        },
        //是否分销商
        isfxs:function(){
            if(this.getGetters('user').isfxs == true || this.getQuery('isfxs') == 'true'){
                let newarr = this.selectvalueList;
                this.selectvalueList = newarr.slice(1);
                this.selectvalue = this.selectvalueList[0].key;
            }else{
                this.selectvalue = this.selectvalueList[0].key;
            }
        },

    },
    mounted() {
        this.getInfo('','','','');
        this.isfxs();
    }
}
