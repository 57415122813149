//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import settlementDatail from './settlementDatail.vue'
export default {
    components: { 
        settlementDatail,
    },
    props: {},
    data(){
        return{
            detailData:{},
            tableData:[],
            listStatus:[
                {value:'待确认',key:'1',type:'info'},
                {value:'待开票',key:'2',type:'warning'},
                {value:'待结算',key:'3',type:'primary'},
                {value:'已结算',key:'4',type:'success'},
            ]
        }
    },
    computed: {
        rowguid:function(){
            return this.getQuery('rowguid')
        }  
    },
    watch:{

    },
    methods: {
        getDetail:function(){
            this.$get(this.$store.getters.settUrl + '/hcgSettItem/'+this.rowguid,{

            }, function(data) {
                this.detailData = data.hcgItem;
                this.tableData = data.hcgOrderInfo;
            })
        },
        //导出
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    isExport: true,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
                });
                window.open('/bhtong/settmg/hcgSettItem/' + this.rowguid + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        //返回
        goBack:function(){
            this.$router.go(-1);
        },
        //下载文件
        downloadFile:function(val){
            window.open(val);
        },
        //筛选状态
        filterStatus:function(arr,val){
            let status =  arr.filter(function(item){
                return item.key == val ;
            })
            if(status != ''){
                return status[0]; //返回筛选的对象
            } else{
                return {};
            }
        },
    },
    mounted:function(){
        this.getDetail();   
    }
}
