//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page'
import COMMON_METHODS from '@mixins/common-methods.js'
import applyHcgSettItem from './applyHcgSettItem.vue'
import uploadfp from '../settItem/uploadfp.vue'
import uploadpz from '../settItemChild/uploadpz.vue'


export default {
    mixins: [ LIST_MIXIN,COMMON_METHODS],
    props:[],
    components:{applyHcgSettItem,uploadpz,uploadfp},
    data: function(){
        return{
            pgData:{
                itemName:'',
                itemStatus:'',
                isConfirmList:this.getQuery('isConfirmList'),
            },
            tableData:[],
            dialogApplyVisible:false,
            dialogUploadfpVisible:false,
            dialogUploadpzVisible:false,
            formTitle:'',
            rowguid:'',
            requestUrl:'',
            token:'',
            listStatus:[
                {value:'待确认',key:'1',type:'info'},
                {value:'待开票',key:'2',type:'warning'},
                {value:'待结算',key:'3',type:'primary'},
                {value:'已结算',key:'4',type:'success'},
            ],
            needinvoice:'',
            appId:'',
            partnerNamelist:[],
        }
    }, 
    computed:{
 
    },
    watch:{
        dialogApplyVisible: function(n, o){
            if(!n){
                this.doSearch();
            }
        },
        dialogUploadpzVisible: function(n, o){
            if(!n){
                this.doSearch();
            }
        },
        dialogUploadfpVisible:function(n,o){
            if(!n){
                this.doSearch();
            }
        }
    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        //申请结算
        applyJS:function(){
            this.dialogApplyVisible = true;
            this.formTitle = '申请结算';
        },
        //清单详情
        listDetail:function(row){
            if(this.getQuery('token')){
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.id,
                        token:this.getQuery('token')
                    }
                })
            }else{
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.id
                    }
                }) 
            }
        },
        //清单确认
        listAffirm:function(row){
            var that = this;

            ShowConfirm(`<span style="font-size:15px;">是否确认 ${row.itemName} 清单？</span>`, 'warning', function() {
            that.$post(that.$store.getters.settUrl + '/hcgSettItem/confirm',{
                rowguid:row.id
            },function(data,res){

                ShowMsg('确认成功','success');
                that.doSearch();
            })
            }, function() {
                
            })     
      
        },
        //删除清单
        listDel:function(row){
            var that = this;
            ShowConfirm('删除后需重新添加结算清单，是否确定？' , 'warning', function() {
                that.$delete(that.$store.getters.settUrl + '/hcgSettItem/'+row.id,{},function(data){
                    ShowMsg('删除成功','success');
                    that.doSearch();
                })
            }, function() {
                  ShowMsg('已取消删除操作','info');
            })
        },
        //开具发票
        kfp:function(row){
            this.needinvoice = this.getQuery('needinvoice') ? this.getQuery('needinvoice') : this.getGetters('user').needinvoice,
            this.requestUrl = '/bhtong/settmg/hcgSettItem/invoice',
            this.formTitle = '上传发票';
            this.dialogUploadfpVisible = true;
            this.rowguid = row.id;
        },
        //财务确认
        uploadPz:function(row){
            this.requestUrl = '/bhtong/settmg/hcgSettItem/sett',
            this.dialogUploadpzVisible = true;
            this.formTitle = '财务确认';
            this.rowguid = row.id;
            this.itemName = row.itemName;
            this.appId = row.appId;
        },
        //导出清单
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    itemName: this.pgData.itemName,
                    itemStatus: this.pgData.itemStatus,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token,
                    isExport: true,
                });
                window.open('/bhtong/settmg/hcgSettItem' + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        //获取合作单位
        getPartnerName:function(){
            this.$get(`${this.getGetters('partnerUrl')}/channelInfo/listSelect`,{},function(data){
                this.partnerNamelist = data;
            })
        },
    },
    mounted() {
        this.getPartnerName();
    }
}
