//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    components: { },
    props: {
        tableData:{
            type:Array,
            default:function(){
                return []
            }
        }
    },
    data(){
        return{

        }
    },
    computed: {


    },
    watch:{

    },
    methods: {

    },
    mounted:function(){
        
    }
}
